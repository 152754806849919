import {
  ActionIcon,
  Button,
  Center,
  Group,
  SimpleGrid,
  Text,
  TextInput,
  Title,
  createStyles,
  Textarea
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import React from 'react';

import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',
    backgroundImage: `linear-gradient(135deg, rgba(142,83,161,1) 0%, rgba(45,38,95,1) 100%)`,
    // borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan('sm')]: {
      padding: `calc(${theme.spacing.xl} )`,
    },
  },

  inner: {
    // borderRadius: theme.radius.md,
    padding: '0 100px',

    [theme.fn.smallerThan('sm')]: {
      padding: 0,
    },
  },

  title: {
    fontFamily: `${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
    fontSize: 46
  },

  description: {
    color: '#1D1E35',
    // maxWidth: rem(300),

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
    // marginLeft: 50
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    color: '#1D1E35',
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactUs() {
  const { classes } = useStyles();
  const [message, setMessage] = useState<string>();
  const { isLoading, isError, error, mutate } = useMutation(createContactUs);
  async function createContactUs(contactUs) {
    try {
      const response = await axios.post('/contactUs', contactUs)
      setMessage('Welcome to the CrowdVision community!');
    } catch (err) {
      console.error(err);
      setMessage('There was an error in submitting the data, please refresh and retry again.');
    }
  }
  const postContactUs = (contactUs) => {
    mutate(contactUs)
  }
  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const icons = social.map((Icon, index) => (
    <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));

  return (
    <div className={classes.wrapper} id='getInTouch'>
      <SimpleGrid cols={2} spacing={30} 
      // breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
      breakpoints={[
        { maxWidth: 'md', cols: 2, spacing: 'xl' },
        { maxWidth: 'sm', cols: 1, spacing: 'xl' },
      ]}
      >
        <div className={classes.inner}>
          <Title className={classes.title}>Sign Up for our Newsletter</Title>
          <Text size={'lg'} className={classes.description} mt="sm">
            Sign up for our newsletter and stay in the loop with the latest updates at CrowdVision! Join our community today
            and be the first to know about upcoming events and product releases and get access to exclusive offers!
          </Text>
        </div>
        {message ?
          <Center style={{ marginTop: 20 }}><Text size={'xl'} color='white'>{message}</Text></Center>
          :
          <form className={classes.form} onSubmit={form.onSubmit((values) => postContactUs(values))}>
            <TextInput
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps('email')}
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <TextInput
              label="Name"
              placeholder="Your name"
              {...form.getInputProps('name')}
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Textarea
              label="Your valuable input"
              {...form.getInputProps('message')}
              placeholder="I'd love to stay up to date on CrowdVision news"
              minRows={4}
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />

            <Group position="right" mt="md">
              <Button type="submit" className={classes.control}>
                Sign Up
              </Button>
            </Group>
          </form>
        }
      </SimpleGrid>
    </div>
  );
}