import { Avatar, Card, Center, Container, Image, SimpleGrid, Text, Title, createStyles, rem, useMantineTheme } from '@mantine/core';
import { Icon3dCubeSphere, IconHandRock, IconUserHeart } from '@tabler/icons-react';
import React from 'react';


export const MOCKDATA = [
  {
    icon: IconHandRock,
    image: 'images/Record.png',
    title: 'Record',
    description:
      "Capture a short clip of the concert you are attending",
  },
  {
    icon: Icon3dCubeSphere,
    image: 'images/Upload.png',
    title: 'Upload',
    description: 'Upload your video to the platform',
  },
  {
    icon: Icon3dCubeSphere,
    image: 'images/Personalized.png',
    title: 'Personalize',
    description: 'Pick what moments you want to see and how you want them to look',
  },
  {
    icon: IconUserHeart,
    image: 'images/Enjoy.png',
    title: 'Enjoy/Share',
    description:
      'Sit back and enjoy the show as CrowdVision gives you your unique video',
  },
];

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 1)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    fontFamily: `${theme.fontFamily}`,
    fontWeight: 500,
    marginBottom: theme.spacing.md,
    textAlign: 'center',
    fontSize: 56,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(28),
      textAlign: 'left',
    },
    padding: '40px 0 10px 0'
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left',
    },
  },

}));

interface FeatureProps {
  icon: React.FC<any>;
  title: React.ReactNode;
  image: string;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, image, description }: FeatureProps) {
  const theme = useMantineTheme();

  return (
    <div style={{ position: 'relative' }}>
      <Card shadow="sm" withBorder radius="xl" >
        <Card.Section style={{ paddingTop: 35 }} inheritPadding py="xs">
          <Center><Text size={'lg'} weight={500}>{title}</Text></Center>
        </Card.Section>
        <Card.Section p={'0 10px'} >
          <Image
            src={image}
            alt=""
          />
        </Card.Section>
        <Card.Section inheritPadding mt="sm" pb="md">
          <Center>
            <Text style={{
              fontSize: 16, fontWeight: 300,
              textAlign: 'center', color: '#1D1E35',
              verticalAlign: 'middle',
              minHeight: 100
            }}>{description}</Text>
          </Center>
        </Card.Section>
      </Card>
    </div>
  );
}



interface FeaturesGridProps {
  title: React.ReactNode;
  description: React.ReactNode;
  data?: FeatureProps[];
}

export function FeaturesGrid({ title, description, data = MOCKDATA }: FeaturesGridProps) {
  const { classes } = useStyles();
  const features = data.map((feature, index) => <div>
    <div style={{ position: 'relative', top: 30, zIndex: 100 }}>
      <Center>
        <Avatar src={null} alt="" variant="filled" color="lime" size="lg" radius="xl"
        > <span style={{ color: 'black' }}>{index + 1}</span></Avatar>
      </Center>
    </div>

    <Feature {...feature} key={index} />
  </div>);

  return (
    <>
      <Center><Title className={classes.title}>{title}</Title></Center>
      <div style={{
        background: 'linear-gradient(172deg, rgba(223,81,157,1) 0%, rgba(142,83,161,1) 100%)',
      }}>
        <Container className={classes.wrapper} id="features">
          <Container size={560} p={0}>
            <Text size="sm" className={classes.description}>
              {description}
            </Text>
          </Container>
          <SimpleGrid
            cols={4}
            spacing={10}
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: 'xl' },
              { maxWidth: 755, cols: 1, spacing: 'xl' },
            ]}
          >
            {features}

          </SimpleGrid>
        </Container>
      </div>
    </>
  );
}