import { MantineProvider } from '@mantine/core';
import { HeroBullets } from './HeroBullets.js';
import { ContactUs } from './components/ContactUs.tsx';
import { FeaturesGrid } from './components/FeaturesGrid.tsx';
import { FooterLinks } from './components/FooterLinks.tsx';
import { HeaderAction } from './components/HeaderAction.tsx';

import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/100-italic.css"; // Specify weight and style
import "@fontsource/poppins/100.css"; // Specify weight
import "@fontsource/poppins/200-italic.css"; // Specify weight and style
import "@fontsource/poppins/200.css"; // Specify weight
import "@fontsource/poppins/300-italic.css"; // Specify weight and style
import "@fontsource/poppins/300.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500-italic.css"; // Specify weight and style
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600-italic.css"; // Specify weight and style
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/700-italic.css"; // Specify weight and style
import "@fontsource/poppins/700.css"; // Specify weight


import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Demo } from './Demo.js';

const defaultQueryFn = async ({ queryKey }) => {
  const [_key] = queryKey;
  const { data } = await axios.get(_key);
  return data;
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

export default function App() {
  const menu = [
    // {
    //   "link": "#home",
    //   "label": "Home"
    // },
    {
      "link": "#features",
      "label": "Try Now"
    },
    {
      "link": "/pricing",
      "label": "How it works"
    },
    {
      "link": "/link1",
      "label": "Contact Us"
    },
  ];

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS
      theme={{
        colors: {
          'cv-purple': ['#530781', '#B183BA', '#D8C1DD', '#75389a', '#8651a6', '#976ab3', '#C2D83c', '#b18fc6', '#128797', '#147885'],
          'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
          'lime': ['#C2D83C', '#C2D83C', '#C2D83C', '#C2D83C', '#C2D83C', '#C2D83C', '#C2D83C', '#E00890', '#C50E82', '#AD1374'],
        },
        primaryColor: 'cv-purple',
        fontFamily: 'Poppins, sans-serif',
        fontFamilyMonospace: 'Monaco, Courier, monospace',
        headings: { fontFamily: 'Poppins, sans-serif' },

      }}
    >
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <div id="home" style={{ marginTop: 10 }}>
            <HeaderAction links={menu} />
          </div>
          <div style={{
            backgroundImage: 'url(images/wave.svg)',
            backgroundRepeat: 'repeat-x',
            height: 55,
            marginTop: -20
          }}>
          </div>
          <div style={{
            background: 'linear-gradient(45deg, rgba(223,81,157,1) 0%, rgba(194,216,60,1) 100%)',
            boxShadow: '0 4px 2px -1px #E1E1E4'
          }}>
            <HeroBullets />
          </div>
          <div>
            <FeaturesGrid
              title='How it works'
            // description='You Capture, We Curate'
            />
          </div>
          <div>
            <Demo />
          </div>
          <div>
            <ContactUs />
          </div>
          <div>
            <FooterLinks />
          </div>
        </div>
      </QueryClientProvider>
    </MantineProvider>
  );
}