import {
  ActionIcon,
  Button,
  Center,
  Group,
  SimpleGrid,
  Text,
  TextInput,
  Title,
  createStyles,
  Image,
  Grid
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import React from 'react';
import { DropzoneButton } from './components/DropzoneButton.tsx';

import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: 'border-box',

    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan('sm')]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  crowd: {
    position: 'relative',
    backgroundImage:'url(images/crowd.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function Demo() {
  const { classes } = useStyles();
  const [message, setMessage] = useState();
  const { isLoading, isError, error, mutate } = useMutation(createDemo);
  async function createDemo(demo) {
    try {
      const response = await axios.post('/demo', demo)
      setMessage('Welcome to the CrowdVision community!');
    } catch (err) {
      console.error(err);
      setMessage('There was an error in submitting the data, please refresh and retry again.');
    }
  }
  const postDemo = (demo) => {
    mutate(demo)
  }
  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  const [isDiveIn, setDiveIn] = useState(true);

  return (
    <div className={classes.wrapper} id='getDemo'>
      <Grid className={classes.crowd}>
      <Grid.Col md={0} lg={6} ><div></div></Grid.Col>
      <Grid.Col md={0} lg={6}><div style={{padding: 20}}>
            <DropzoneButton setOpen={setDiveIn} />
          </div></Grid.Col>
    </Grid>
    </div>
  );
}