import { Button, CloseButton, Container, Flex, Group, SimpleGrid, Text, TextInput, createStyles, rem, Title, Center } from '@mantine/core';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { IconCloudDownload, IconCloudUpload, IconDownload, IconX } from '@tabler/icons-react';
import axios from 'axios';
import { default as React, useRef, useState } from 'react';
import { useMutation } from 'react-query';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        marginBottom: rem(30),
        backgroundColor: 'white',
        borderRadius: 10,
        color: "#1D1E35"
    },

    dropzone: {
        borderWidth: rem(1),
        paddingBottom: rem(50),
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },

    control: {
        position: 'absolute',
        width: rem(250),
        left: `calc(50% - ${rem(125)})`,
        bottom: rem(-20),
    },

    form: {
        backgroundColor: theme.white,
        // padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
    },
}));

interface DropzoneButtonProps {
    setOpen?: (open: boolean) => void;
}

export function DropzoneButton({ setOpen }) {
    const [videos, setVideos] = useState<any[]>([]);

    const { classes, theme } = useStyles();
    const openRef = useRef<() => void>(null);

    const [message, setMessage] = useState<string>();
    const { isLoading, isError, error, mutate } = useMutation(createFormValues);
    async function createFormValues(formValues) {
        try {
            let formData = new FormData();
            if (formValues) {
                formData.append("email", formValues.email);
                if (videos) {
                    formData.append('file', JSON.stringify(videos[0]));
                    // formValues.files.forEach(file => {
                    //     formData.append("files[]", JSON.stringify(file));
                    // });
                }
            }

            // console.log('>>>>>>>', formValues, formData)
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            // const response = await axios.post('/videos/upload', result, config);
            await axios.post("/videos/upload",
                formData,
                config);
            setMessage('Your request has been sent successfully.');
        } catch (err) {
            setMessage('There was an error in submitting the data, please refresh and retry again.');
            console.error(err);
        }
    }
    const postForm = (formValues) => {
        formValues.files = videos;
        // mutate(formValues)
        setMessage(`We are diligently working to make CrowdVision a reality! Once the platform is ready, an email will be dispatched to the address you provided: ${formValues.email}.`);
    }
    const form = useForm({
        initialValues: {
            email: '',
            files: [] as any[],
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    return (
        <div className={classes.wrapper}>
            {
                message ?
                    <Container p={50} style={{ width: '100%' }}>
                        <Group position="center">
                            <IconCloudDownload
                                size={rem(50)}
                                color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black}
                                stroke={1.5}
                            />
                        </Group>

                        <Text ta="center" fw={400} fz="md" mb="xl">
                            {message}
                        </Text>
                    </Container>
                    :
                    <>
                        <SimpleGrid cols={3}>
                            {videos.map(video => (
                                <Container>
                                    {video.type === "video/mp4" || video.type === "video/mov" ?
                                        <video width={160} preload="" src={video.preview}></video>
                                        :
                                        <img
                                            src={video.preview}
                                            // style={img}
                                            // Revoke data uri after image is loaded
                                            onLoad={() => { URL.revokeObjectURL(video.preview) }}
                                            width={160}
                                        />
                                    }
                                    <Text fz="xs" lineClamp={1}>{video.name} </Text>
                                </Container>
                            ))
                            }
                        </SimpleGrid>
                        <Dropzone
                            openRef={openRef}
                            onDrop={(files: FileWithPath[]) => {
                                // console.log('>>>>>>>>>', files);
                                const fs = (files.map(file => Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                })));
                                setVideos(prevItems => [
                                    ...prevItems, ...fs
                                ])
                            }}
                            className={classes.dropzone}
                            radius="md"
                            accept={['video/mp4', 'video/quicktime', '', MIME_TYPES.png]}
                            maxSize={100 * 1024 ** 2}
                        >
                            <div style={{ pointerEvents: 'none', textAlign: 'center', padding: '40px 40px 0 40px' }}>
                                <Group position="center">
                                    <Dropzone.Accept>
                                        <IconDownload
                                            size={rem(50)}
                                            color={theme.colors[theme.primaryColor][6]}
                                            stroke={1.5}
                                        />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        {/* <IconX size={rem(50)} color={theme.colors.red[6]} stroke={1.5} /> */}
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <div style={{}}>
                                        <Title> Get your own personal demo! </Title>
                                        <Text>Interested in your own CrowdVision demo? Upload videos from your last concert to get a personalized video!</Text>
                                        </div>
                                    </Dropzone.Idle>
                                </Group>

                                <Button ta="center" fz="lg" mt="xl" rightIcon={<IconCloudUpload/>} style={{color: "#1D1E35"}}>
                                    <Dropzone.Accept>Drop files here</Dropzone.Accept>
                                    <Dropzone.Reject>MP4 file less than 100mb</Dropzone.Reject>
                                    <Dropzone.Idle><div >Upload videos</div></Dropzone.Idle>
                                </Button>
                                <Text ta="center" fz="sm" mt="xs" c="dimmed">
                                    Click or Drag&apos;n&apos;drop files here to upload. We can accept only <i>.mp4</i>, <i>.mov</i> files that
                                    are less than 100mb in size.
                                </Text>
                            </div>
                        </Dropzone >
                        <form className={classes.form}
                            encType='multipart/form-data'
                            onSubmit={form.onSubmit((values) => postForm(values))}>
                            <TextInput p="md"
                                label="Email"
                                placeholder="your@email.com"
                                {...form.getInputProps('email')}
                                required
                            />
                            {videos && videos.length > 0 ?
                                <Button className={classes.control} size="md" radius="xl"
                                    color="orange"
                                    type="submit">
                                    Submit</Button>
                                :
                                <></>
                            }                        
                        </form>
                    </>
            }

        </div >
    );
}