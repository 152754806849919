import {
  Burger,
  Button,
  Center,
  Container,
  Group,
  Header,
  Menu,
  createStyles,
  rem
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import React from 'react';

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#1D1E35',

    paddingBottom: 10
  },

  headerText: {
    fontSize: 24,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 20,
      textAlign: 'left',
      marginLeft: -20
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#1D1E35',
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#B183BA',
      color: '#000'
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

interface HeaderActionProps {
  links: { link: string; label: string; links: { link: string; label: string }[] }[];
}

export function HeaderAction({ links }: HeaderActionProps) {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>{item.label}</Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => event.preventDefault()}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size={rem(12)} stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <>
        <a
          key={link.label}
          href={link.link}
          className={classes.link}
        // onClick={(event) => event.preventDefault()}
        >
          {link.label}
        </a>
      </>
    );
  });

  return (
    <Header height={HEADER_HEIGHT} sx={{ borderBottom: 0 }} mb={0}>
      <Container className={classes.inner} fluid>
        <Group align={'center'}>
          {/* <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" /> */}
          <div style={{paddingTop: 7}}>
          <img src='/images/logo.png' width='75' />
          </div>
          <div className={classes.headerText}>CrowdVision</div>
        </Group>
        {/* <Group spacing={5} className={classes.links}>
          {items}
        </Group> */}
        <Button size="xl"  variant="gradient" gradient={{ from: '#8e53a1', to: '#df519d' }}
        style={{fontSize: 15, fontWeight: 300}}
        h={30} component="a" href="#getInTouch" >
          Sign Up
        </Button>
      </Container>
    </Header>
  );
}