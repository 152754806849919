import {
  ActionIcon,
  Button,
  Card,
  Grid,
  Group,
  Text,
  Title,
  createStyles,
  rem
} from '@mantine/core';
import { IconShare } from '@tabler/icons-react';
import { useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import ReactPlayer from "react-player";

const useStyles = createStyles((theme) => ({
  inner: {
    maxWidth: '99vw',
    [theme.fn.largerThan('md')]: {
      paddingTop: `calc(${theme.spacing.xl} * 4)`,
      paddingBottom: `calc(${theme.spacing.xl} * 4)`,
      paddingLeft: `calc(${theme.spacing.xl} * 2)`,
      paddingRight: `calc(${theme.spacing.xl} * 2)`,
    },
    [theme.fn.smallerThan('md')]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      paddingLeft: `calc(${theme.spacing.xl} * 1)`,
      paddingRight: `calc(${theme.spacing.xl} * 1)`,
    },
  },

  content: {
    [theme.fn.largerThan('md')]: {
      paddingLeft: `calc(${theme.spacing.xl} * 3)`,
      paddingRight: `calc(${theme.spacing.xl} * 3)`,
    },
    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.white,
    fontFamily: `${theme.fontFamily}`,
    fontSize: rem(40),
    // lineHeight: 1.2,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
    },
    fontWeight: 800,
    // fontStretch: 'ultra-condensed'
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
    color: '#1D1E35',
    fontWeight: 300
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export function HeroBullets() {
  const { classes } = useStyles();
  const [isDiveIn, setDiveIn] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  // const handleOpenPicker = () => {
  //   openPicker({
  //     clientId: "401631508173-qjqpb860jl72bek394mdeq0mbcs838qo.apps.googleusercontent.com",
  //     // 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
  //     developerKey: "AIzaSyArXKeaYW1O39fNeSy8JXhS04n_7JZay7k",
  //     viewId: "DOCS",
  //     // token: token, // pass oauth token in case you already have one
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: true,
  //     // customViews: customViewsArray, // custom view
  //     callbackFunction: (data) => {
  //       if (data.action === 'cancel') {
  //         console.log('User clicked cancel/close button')
  //       }
  //       console.log(data)
  //     },
  //   })
  // }
  return (
    <Grid className={classes.inner}>
      <Grid.Col md={12} lg={6} className={classes.content}>
        <Title className={classes.title}>
          <b>Elevating Event Experiences:  </b>
        </Title>
        <Title className={classes.title} style={{ fontWeight: 400 }}>
          Both During and After
        </Title>
        <br />
        <Text color="#1D1E35" mt="md" style={{ fontFamily: 'sans-serif', fontSize: '20px', paddingRight: 50 }}>
          CrowdVision provides personalized and enhanced live event videos, Miss a highlight at a concert? Or have shaky and blurry footage? Our custom AI model improves your clips and uses crowdsourcing to ensure you won’t miss any moment of the performance.
          <p />
          With CrowdVision, you spend less time recording at concerts, and more time enjoying and sharing unforgettable moments.
        </Text>
        <br />


        <Group mt={30}>
          {
            <Button size="xl" className={classes.control}
              component="a" href="#getDemo"
            >
              Get your own personal demo
            </Button>
          }
        </Group>
      </Grid.Col>

      <Grid.Col md={0} lg={6} className={classes.image}>
        <Card shadow="sm" radius="xs">
          <Card.Section inheritPadding py="xs">
            <Group position="apart">
              <Text weight={500}>Watch a short demo to see CrowdVision in action!</Text>
              <div>
                <ActionIcon variant="transparent" color="violet"><IconShare size="1.1rem" /></ActionIcon>
              </div>
            </Group>
          </Card.Section>
          <Card.Section mt="sm" p={'0 15px'}>
            <ReactPlayer
              width='100%'
              height='27em'
              url="https://youtu.be/1GwjoERY4e0"
            />
          </Card.Section>

          <Card.Section inheritPadding mt="sm" pb="md">
          </Card.Section>
        </Card>
      </Grid.Col>
    </Grid>
  );
}