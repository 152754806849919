import { Container, Text, createStyles, rem, Group, ActionIcon, Anchor } from '@mantine/core';
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import React from 'react';

const useStyles = createStyles((theme) => ({
  footer: {
    // marginTop: rem(120),
    paddingTop: `calc(${theme.spacing.xl} * 1)`,
    paddingBottom: `calc(${theme.spacing.xl} * 1)`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
  },

  logo: {
    maxWidth: rem(200),

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: rem(5),

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    width: rem(160),
  },

  link: {
    display: 'block',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  social: {
    color: '#1D1E35',
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

interface FooterLinksProps {
  data: {
    title: string;
    links: { label: string; link: string }[];
  }[];
}

const MOCKDATA = [
  {
    "title": "About",
    "links": [
      {
        "label": "Features",
        "link": "#"
      },
      {
        "label": "Pricing",
        "link": "#"
      },
      {
        "label": "Support",
        "link": "#"
      },
      {
        "label": "Forums",
        "link": "#"
      }
    ]
  },
  {
    "title": "Project",
    "links": [
      {
        "label": "Contribute",
        "link": "#"
      },
      {
        "label": "Media assets",
        "link": "#"
      },
      {
        "label": "Changelog",
        "link": "#"
      },
      {
        "label": "Releases",
        "link": "#"
      }
    ]
  },
  {
    "title": "Community",
    "links": [
      {
        "label": "Join Discord",
        "link": "#"
      },
      {
        "label": "Follow on Twitter",
        "link": "#"
      },
      {
        "label": "Email newsletter",
        "link": "#"
      },
      {
        "label": "GitHub discussions",
        "link": "#"
      }
    ]
  }
];

export function FooterLinks({ data = MOCKDATA }: FooterLinksProps) {
  const { classes } = useStyles();

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text<'a'>
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      {/* <Container className={classes.inner}>
        <div className={classes.logo}>
        <img src='/images/logo.png' width='100'/>
          <Text size="xs" color="dimmed" className={classes.description}>
            CrowdVision
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container> */}
      <Container
      // className={classes.afterFooter}
      >
        <Group>
        <Text color="dimmed" size="sm">
          <img src='/images/logo.png' width='70' />&nbsp;
        </Text>
        <Text>CrowdVision</Text>
        </Group>
        <Text>Tel: <Anchor href="tel:647-643-5625" color={'#1D1E35'}>647-643-5625</Anchor></Text>
        <Text>Email: <Anchor href="mailto:info@crowdvision.ca" color={'#1D1E35'}>info@crowdvision.ca</Anchor></Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
         <ActionIcon size="lg">
            <IconBrandFacebook size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandTwitter size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandLinkedin size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandYoutube size="1.05rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size="1.05rem" stroke={1.5} />
          </ActionIcon>
        </Group>
        <Text align='right'>© 2023 crowdvision.ca All rights reserved.</Text>
        <Text p={10} size={'xs'} c="gray">Disclaimer: Please be advised that our team is currently hard at work developing the CrowdVision platform. Our aim is to provide you with an optimal and seamless user experience. However, the platform is currently in its development stage, and as such, may not yet be available for full use. We appreciate your understanding and patience during this time. We are doing our utmost to finalize the platform and to launch it as soon as possible. Please keep an eye on our communications for updates regarding the platform's availability.</Text>
      </Container>
    </footer>
  );
}